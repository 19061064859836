@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

// Set your brand colors
$purple: #8a4d76;
$pink: #fa7c91;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
$black: #000000;

$dark-blue: #124CB8;
$light-blue: #80ACFF;
$gold: #FFC23D;

// Update Bulma's global variables
$family-sans-serif: 'Nunito', sans-serif;
$primary: $dark-blue;
$link: $dark-blue;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
$control-border-width: 1px;
$input-border-color: $black;
$input-shadow: none;

// Import only what you need from Bulma
@import '../node_modules/bulma/';
